import * as React from "react";
import Link from "next/link";
import { Form, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ChevronDown from "react-feather/dist/icons/chevron-down";
import { PlacesComponent } from "../../generated/apolloComponents";

type Props = {
  country: string | null | undefined,
}

const EventFilter: React.FunctionComponent<Props> = ({ country }) => {
  const [dropdownPlaceOpen, setPlaceOpen] = React.useState<boolean>(false);
  const togglePlace = () => setPlaceOpen(!dropdownPlaceOpen);

  return (
    <PlacesComponent>
      {({ data }) => (
        <Form>
          <div className="d-flex justify-content-start pb-1">
            <div className="flex-shrink-1 pb-1 pr-4">
              <ButtonDropdown isOpen={dropdownPlaceOpen} toggle={togglePlace}>
                <DropdownToggle color="link" className="p-0">
                  {country && <>Events in {country}<ChevronDown /></>}
                  {!country && <>Events in any place<ChevronDown /></>}
                </DropdownToggle>
                <DropdownMenu>
                  <Link href={{ pathname: '/' }} scroll={false}><DropdownItem>Any place</DropdownItem></Link>
                  {data && data.places!.map(place => <Link href={{ pathname: '/', query: { country: place!.country } }} key={`country.${place!.country}`} scroll={false}><DropdownItem>{place!.country}</DropdownItem></Link>)}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        </Form>
      )}
    </PlacesComponent>
  )
};

export default EventFilter;
