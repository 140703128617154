import * as React from "react"
import { useState, useEffect } from "react";
import Typist from 'react-typist';

type Props = {
  title: string,
  subtitle?: string,
};

const Hero: React.FunctionComponent<Props> = ({ title, subtitle }) => {
  const [count, setCount] = useState(1);
  useEffect(() => {
    setCount(1);
  }, [count]);
  return (
    <div className="pb-5 bg-light">
      <div className="bg-img-hero" style={{backgroundImage: 'url(/static/img/hero-bg1.jpg)', backgroundPositionY: 'bottom'}}>
        <div className="gradient-overlay-half-primary-v1">
          <div className="container space-2 space-md-3">
            <div className="w-md-80 w-lg-60 text-center mx-auto">
              {/* Title */}
              <div className="mb-5">
                <h1 className="display-4 font-size-md-down-5 text-white mb-0">
                  Artificial Intelligence &amp; Machine Learning
                  {count ? <Typist onTypingDone={() => setCount(0)}>
                    Events
                    <Typist.Backspace count={6} delay={3000} />
                    Conferences
                    <Typist.Backspace count={11} delay={2000} />
                    Summits
                    <Typist.Backspace count={7} delay={2000} />
                    Workshops
                    <Typist.Backspace count={9} delay={2000} />
                    Exhibitions
                    <Typist.Backspace count={11} delay={2000} />
                  </Typist> : " "}
                </h1>
              </div>
              {/* End Title */}
              {/* Text/Links */}
              <h2 className="lead text-white-70">Curated list of the best AI &amp; ML events, conferences, summits, exhibitions, workshops, and trainings</h2>
              {/* End Text/Links */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
