import { Card, CardBody } from "reactstrap";
import OutboundLink from "components/ui/OutboundLink";


export type Props = {
  className?: string,
};

const ConferenceVideosCard: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <Card className={`bg-img-hero text-white border-0 mb-3 videos gradient-overlay-half-dark-v3 rounded-pseudo ${className}`} style={{backgroundImage: 'url("/static/img/video-card.jpg")'}}>
      <CardBody className="p-5">
        <h2 className="h1 mb-2 text-white">Conference Videos</h2>
        <p className="text-white">Missed a conference? No problem! Get ready for the conference videos! All AI &amp; ML conference videos in one place. </p>
        <p className="text-white">
          <a className="btn btn-lg btn-success" href="/videos">Watch videos</a>
        </p>
      </CardBody>
    </Card>
  );
};

export default ConferenceVideosCard;
