import { Button, Alert, Form, Spinner, Row, Col, Label } from "reactstrap";
import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { InputField } from "components/InputField";
import { NewsletterSubscribeMutationComponent } from "generated/updateevent/apolloComponents";
import { useState } from "react";


const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

export type Props = { };

const SubscribeRow: React.FunctionComponent<Props> = () => {
  const [message, setMessage] = useState<string>();
  const [status, setStatus] = useState<string>('WAITING');
  return (
      <Row className="p-5">
        <Col className="mb-5" sm={12} md={6}><img src="/static/img/sittingman.png" className="img-fluid" /></Col>
        <Col className="mb-5" sm={12} md={6}>
          <h2 className="h4 mb-2">Want more? Subscribe to upcoming AI&nbsp;&amp;&nbsp;ML events newsletter</h2>
          <p>Monthly to your email. No spam. Trusted by AI&nbsp;&amp;&nbsp;ML experts. Unsubscribe at any time.</p>
          <div>
            {status === 'ERROR' && <Alert color="danger">{message}</Alert>}
            {(status === 'ERROR' || status === 'WAITING') && <NewsletterSubscribeMutationComponent>
              {newsletterSubscribe => (
                <Formik
                  onSubmit={async (data, { setSubmitting }) => {
                    setSubmitting(true);
                    const response = await newsletterSubscribe({
                      variables: {
                        input: data
                      }
                    });
                    setStatus(response.data?.newsletterSubscribe?.result ?? '');
                    setMessage(response.data?.newsletterSubscribe?.message ?? '');
                  }}
                  initialValues={{firstName: '', email: ''}}
                  validationSchema={SignupSchema}>
                  {({ isSubmitting, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Label className="sr-only" for="subscribe-input-name">Your name</Label>
                      <Field name="firstName" placeholder="Your name" component={InputField} className="form-control mb-1" id="subscribe-input-name" />
                      <Label className="sr-only" for="subscribe-input-email">Your e-mail</Label>
                      <Field name="email" placeholder="Your e-mail" component={InputField} className="form-control mb-1" id="subscribe-input-email" />
                      <Button type="submit" color="success" disabled={isSubmitting}>
                        {isSubmitting && <><Spinner size="sm" /> Subscribing...</>}
                        {!isSubmitting && <>Subscribe</>}
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </NewsletterSubscribeMutationComponent>}
            {status === 'SUCCESS' && <>Well done! Please follow the instructions in the email to confirm your email address.</>}
          </div>
        </Col>
      </Row>
  );
};

export default SubscribeRow;
