import * as React from "react"
import ReactGA from 'react-ga';
import { EventsComponent } from "../../generated/apolloComponents";
import EventCard from "./EventCard";
import SubscribeCard from "components/newsletter/SubscribeCard";
import SubscribeRow from "components/newsletter/SubscribeRow";
import { Button, Row, Col } from "reactstrap";
import ConferenceVideosCard from "components/newsletter/ConferenceVideosCard";


type Props = {
  country: string | null | undefined,
};

const PAGE_SIZE = 11;
let page = 1;

const EventList: React.FunctionComponent<Props> = ({ country }) => (
  <EventsComponent variables={{country, first: PAGE_SIZE}} notifyOnNetworkStatusChange={true} >
    {({data, loading, error, fetchMore }) => {
      if (error) return <div>Something went wrong. Please try reloading page in a moment.</div>;
      const loadMore = () => {
        fetchMore({
          variables: {
            after: data!.events!.pageInfo.endCursor,
          },
          // @ts-ignore
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...fetchMoreResult,
              events: {
                ...fetchMoreResult.events,
                edges: [
                  ...prev.events!.edges,
                  ...fetchMoreResult.events!.edges,
                ],
              },
            };
          },
        });
        ReactGA.event({
          category: 'Event List',
          action: 'Show More Click',
          label: `Page ${page}`
        });
        page++;
      }
      if (data) return (<>
        <Row>
          {data!.events!.edges.map((edge, index) => {
            const isFarFromEndOfList = index < data.events!.edges!.length - 4;
            const canShowSubscribe = index > 0 && (index % PAGE_SIZE) % 5 == 0 && (index % PAGE_SIZE) % 10 > 0;
            return <React.Fragment key={index}>
              <Col md={6} lg={4} className="mb-5" key={edge!.node!.id}>
                <EventCard {...edge!.node!} />
              </Col>
              {canShowSubscribe && isFarFromEndOfList && <Col md={6} lg={4} className="mb-5" key="event-list-subscribe">
                {index < 6 && <ConferenceVideosCard className="shadow h-100" />}
                {index >= 6 && <SubscribeCard className="shadow h-100" />}
              </Col>}
            </React.Fragment>
          })}
        </Row>
        {(data.events && data.events.pageInfo && data.events.pageInfo.hasNextPage || loading) && <div className="d-flex justify-content-center pb-4">
          <Button onClick={loadMore} disabled={loading} color="primary">{loading ? 'Loading...' : 'Show more'}</Button>
        </div>}
        {(data.events && data.events.pageInfo && !data.events.pageInfo.hasNextPage) && <div className="d-flex justify-content-center pb-4">
          <SubscribeRow />
        </div>}
      </>);
      return <p className="py-4">Loading...</p>
    }}
  </EventsComponent>
)

export default EventList
