import * as React from 'react';
import Head from 'next/head';
import { useRouter } from "next/router";
import Layout from '../components/Layout';
import EventList from '../components/event/EventList';
import { withApollo } from '../lib/apollo';
import EventFilter from '../components/ui/EventFilter';
import headTitle from '../components/ui/HeadTitle';
import { Container } from 'reactstrap';
import Hero from '../components/ui/Hero';
import BreadcrumbJsonLd, { ItemListElement } from '../lib/BreadcrumbJsonLd';

const IndexPage: React.FunctionComponent = () => {
  const router = useRouter();
  const { country: countryQuery } = router.query;
  const country = Array.isArray(countryQuery) ? countryQuery[0] : countryQuery;
  const title = headTitle(country ? `Artificial Intelligence & Machine Learning Events in ${country}` : undefined);
  const heroTitle = country ? `Artificial Intelligence & Machine Learning Conferences in ${country}` : `Artificial Intelligence & Machine Learning Conferences`;
  const heroSubtitle = country ? `Curated list of the best AI & ML events in ${country}` : `Curated list of the best AI & ML events`;
  const description = country ? `List of the best hand-picked artificial intelligence and machine learning events, conferences, summits, workshops, trainings in ${country} in 2020-2021` : `List of the best hand-picked artificial intelligence and machine learning events, conferences, summits, workshops, trainings in 2020-2021 world-wide`;
  const breadcrumbItems: Array<ItemListElement> = country ? [{name: country, item: '/?country=' + encodeURIComponent(country)}] : [];

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta key="og:title" property="og:title" content={title} />
        <meta key="og:url" property="og:url" content={`https://aiml.events${router.asPath}`} />
        <meta key="og:image" property="og:image" content="https://aiml.events/static/img/banner.png" />
        <meta key="og:description" property="og:description" content={description} />
        <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
        <meta key="twitter:title" property="twitter:title" content={title} />
        <meta key="twitter:description" property="twitter:description" content={description} />
        <meta key="twitter:image" property="twitter:image" content="https://aiml.events/static/img/banner.png" />
        <meta name="description" content={description} />
      </Head>
      <Layout>
        <Hero title={heroTitle} subtitle={heroSubtitle} />
        <main>
          <div className="pt-4 bg-light">
            <Container>
              <EventFilter country={country} />
              <EventList country={country} />
              <BreadcrumbJsonLd items={breadcrumbItems} />
            </Container>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default withApollo(IndexPage);
