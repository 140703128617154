import * as React from 'react';

export type ItemListElement = {
  item: string;
  name: string;
}

type Props = {
  items: Array<ItemListElement>
}

const BreadcrumbJsonLd: React.FunctionComponent<Props> = ({ items }) => {
  const allItems = [{
    position: 1,
    name: 'AI & ML Events',
    item: '/',
    '@type': 'ListItem'
  }, ...items.map((item, index) => ({'@type': 'ListItem', position: index + 2, ...item}))];
  allItems.forEach(item => item.item = `https://aiml.events${item.item}`)

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": allItems
      }
    )}}></script>
  );
};

export default BreadcrumbJsonLd;
